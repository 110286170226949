<template>
  <div class="discuss">
    discuss
  </div>
</template>

<script>
export default {
  name: 'discuss',
  props: {
    
  }
}
</script>

<style lang="scss">
  .discuss {
    
  }
</style>
